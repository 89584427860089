import { Component, ViewChild } from '@angular/core';
import { SignaturePadComponent, Step } from '@fc-lib';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-sign-waiver',
  templateUrl: './sign-waiver.component.html',
  styleUrl: './sign-waiver.component.scss'
})
export class SignWaiverComponent extends BaseComponent {
  steps: Step[] = [
    { title: 'Your details', currentStep: false, completed: true },
    { title: 'Emergency contacts', currentStep: false, completed: true },
    { title: 'Sign a waiver', currentStep: true, completed: false },
  ]

  @ViewChild("signaturePad") signaturePadComponent: SignaturePadComponent | undefined;

  sign() {
    this.onNext.emit(this.signaturePadComponent?.signaturePad.toDataURL()!);
  }

  html = `<p>
  Eget nunc accumsan ante etiam vel urna! Augue dolor maecenas ultrices curabitur. Placerat sem, metus eleifend sociosqu suspendisse! Mauris dui nibh habitasse velit dis id interdum ultricies diam. Pharetra libero nec nam, arcu curabitur laoreet inceptos in cursus. Imperdiet feugiat quisque in tempor sem! Sagittis conubia pellentesque elit scelerisque sed malesuada hac eleifend eros. Nec orci rhoncus ultricies orci volutpat eu nec porta ad per ad. Feugiat cursus, magna commodo platea. In sapien ante dapibus malesuada feugiat curabitur ac. Vulputate aptent donec imperdiet aliquet egestas hendrerit vivamus praesent tortor malesuada purus facilisi. Magnis iaculis pretium ultrices mi. Integer.
  </p>
  <p>
  Facilisi fusce nascetur senectus, aliquam rhoncus diam tincidunt eleifend himenaeos nec sagittis. Semper, duis eu penatibus? Class euismod etiam nibh turpis taciti, magna taciti sit lobortis aliquet? Faucibus dictumst pulvinar libero suscipit et ornare elementum aptent curae; sodales dapibus fermentum. Lorem elit torquent elit non lobortis donec aenean urna dictumst neque luctus class. Auctor nisi mollis velit vivamus conubia commodo ac non sociis justo? Turpis praesent aenean aliquam? Semper rhoncus.
  </p>
  <p>
  Facilisi convallis, luctus maecenas. Aenean fames magnis augue ultricies mi cubilia lectus mollis. Curae; placerat per sed himenaeos. Purus a nibh sagittis sed enim fringilla mattis potenti himenaeos penatibus? Platea sagittis cursus faucibus euismod gravida fringilla ligula. Per sed ridiculus sapien magna pharetra habitant, rutrum magna varius vel inceptos lectus. Sociosqu consequat massa ullamcorper placerat? Arcu magnis tempus litora mi facilisi vulputate taciti suscipit quis. Fringilla et a magnis eu vehicula tellus! Mauris risus orci hendrerit ante leo praesent posuere. Eget rhoncus porta eget? Bibendum ornare congue adipiscing.
  </p>
  <p>
  Justo proin nam ipsum tincidunt nullam libero? Euismod nisl id blandit conubia fames. Hac fermentum at egestas pellentesque justo? Facilisi ultricies rhoncus magnis. Litora, aliquam eros ad. Volutpat pulvinar potenti amet sapien penatibus tortor ultrices. Cubilia primis condimentum eget fringilla praesent porta porta dignissim himenaeos tincidunt tincidunt! Aliquam nostra euismod magnis imperdiet at pulvinar ipsum aliquam cubilia velit risus. Per auctor nibh lacinia, mollis.
  </p>
  <p>
  Lacinia eleifend ad fames lectus. Ornare varius mauris platea proin vulputate lorem? Congue tellus volutpat commodo gravida cras a torquent sociis. Velit velit ligula, volutpat venenatis convallis nullam sit morbi ridiculus. Massa cum interdum felis. Orci dapibus nec, praesent lobortis erat? Tristique tristique duis porttitor etiam, sed quis. Sit arcu viverra etiam. Mauris dictum rutrum natoque ornare curae; risus consectetur habitant. Suscipit in; eros amet imperdiet faucibus pellentesque aliquam sed praesent eros? Rutrum suscipit taciti id.
  </p>`;
}
