import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StudentCreate } from '../models/student-create.model';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http: HttpClient) { }

  checkEmail(email: string) {
    return this.http.post<boolean>(`${environment.baseUrl}/student/kiosk/check-email`, { email: email });
  }

  createStudent(studentCreateModel: Partial<StudentCreate>) {
    return this.http.post<number>(`${environment.baseUrl}/student/kiosk`, {
      firstName: studentCreateModel.firstName,
      lastName: studentCreateModel.lastName,
      email: studentCreateModel.email,
      mobile: studentCreateModel.mobile,
      emergencyContact: {
        emergencyFirstName: studentCreateModel.emergencyContactFirstName,
        emergencyLastName: studentCreateModel.emergencyContactLastName,
        emergencyRelationship: studentCreateModel.emergencyContactRelationship,
        emergencyMobile: studentCreateModel.emergencyContactMobile
      },
      signatureUrl: studentCreateModel.signatureUrl
    });
  }
}
