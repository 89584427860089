import { Component } from '@angular/core';
import { Step } from '@fc-lib';
import { StudentCreate, StudentDetails, StudentEmergencyContact } from './models/student-create.model';
import { forkJoin, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { StudentService } from './services/student.service';
import { GymService } from './services/gym.service';

enum SignUpStep {
  GetStarted,
  YourDetails,
  EmergencyContact,
  SignWaiver,
  Completed
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sign-up-kiosk';
  currentStep: SignUpStep = SignUpStep.GetStarted;
  signUpStep = SignUpStep;
  loading = true;
  studentCreateModel: Partial<StudentCreate> = {};
  studentDetails: StudentDetails | undefined;
  studentEmergencyContact: StudentEmergencyContact | undefined;
  logoUrl: string | undefined;
  getStartedImageUrl: string | undefined;
  studentDetailsImageUrl: string | undefined;
  emergencyContactImageUrl: string | undefined;

  steps: Step[] = [
    { title: 'Your details', currentStep: false, completed: true },
    { title: 'Emergency contacts', currentStep: true, completed: false },
    { title: 'Sign a waiver', currentStep: false, completed: false },
  ]

  isAuthenticated$: Observable<boolean>;

  constructor(private authService: AuthService, private studentService: StudentService, private gymService: GymService) {
    this.isAuthenticated$ = this.authService.isAuthenticated$;

    this.isAuthenticated$.subscribe(authenticated => {
      if (!authenticated) {
        this.authService.loginWithRedirect();
      }
    })

    forkJoin({
      gym: this.gymService.getGym(),
      settings: this.gymService.getGymAppSettings()
    }).subscribe((result) => {
      this.logoUrl = result.gym.logoUrl;
      this.getStartedImageUrl = result.settings.getStartedImageUrl;
      this.studentDetailsImageUrl = result.settings.studentDetailsImageUrl;
      this.emergencyContactImageUrl = result.settings.emergencyContactImageUrl;
    });
  }

  onDetailsNext(details: StudentDetails) {
    this.studentDetails = details;
    this.studentCreateModel = { ...details };
    this.currentStep = SignUpStep.EmergencyContact;
  }

  onEmergencyContactNext(emergencyContact: StudentEmergencyContact) {
    this.studentEmergencyContact = emergencyContact;
    this.studentCreateModel = { ...this.studentCreateModel, ...emergencyContact};
    this.currentStep = SignUpStep.SignWaiver;
  }

  onSignWaiverNext(signatureUrl: string) {
    this.studentCreateModel.signatureUrl = signatureUrl;
    this.studentService.createStudent(this.studentCreateModel).subscribe(result => {
      this.currentStep = SignUpStep.Completed;
    })
  }

  onNext(step: SignUpStep) {
    this.currentStep = step + 1;
  }

  onBack(step: SignUpStep) {
    this.currentStep = step - 1;
  }
}
