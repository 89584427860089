<div class="w-full flex flex-col h-dvh">
    <div class="mx-[20%] mb-4">
        <fc-stepper [steps]="steps"></fc-stepper>
    </div>
    <div class="flex justify-between grow max-h-[calc(100dvh-80px)]">
        <div class="w-full flex flex-col gap-4 py-4 px-8 justify-between">
            <div class="flex flex-col gap-4">
                <div class="font-semibold text-primary-700 text-center mt-4">Current as of 20 Jan 2024</div>
                <div class="font-semibold text-5xl text-gray-900 pb-4 text-center">Standard Waiver</div>
                <div class="text-xl text-gray-600 text-center">This is the description of the waiver form</div>
                <div class="max-h-[calc(100dvh-620px)] overflow-auto" [innerHTML]="html"></div>
                <div class="flex flex-col gap-2 border-t border-gray-200 pt-2">
                    <span class="self-end">Sign below</span>
                    <app-signature-pad #signaturePad></app-signature-pad>
                </div>
            </div>
            <app-footer (onBack)="onBack.emit()" (onNext)="sign()" class="justify-self-end pb-4"></app-footer>
        </div>
    </div>
</div>
    