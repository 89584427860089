import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CommonLibModule } from '@fc-lib';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { YourDetailsComponent } from './components/your-details/your-details.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { EmergencyContactComponent } from './components/emergency-contact/emergency-contact.component';
import { SignWaiverComponent } from './components/sign-waiver/sign-waiver.component';
import { CompletedComponent } from './components/completed/completed.component';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    GetStartedComponent,
    YourDetailsComponent,
    FooterComponent,
    LoaderComponent,
    EmergencyContactComponent,
    SignWaiverComponent,
    CompletedComponent
  ],
  imports: [
    BrowserModule,
    CommonLibModule,
    NgHeroiconsModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: {
          audience: 'fightcloud',
          redirect_uri: window.location.origin,
      },
      httpInterceptor: {
          allowedList: [
              '*'
          ]
      }
  })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }, provideHttpClient(withInterceptorsFromDi())],
  bootstrap: [AppComponent]
})
export class AppModule { }
