import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gym } from '../models/gym.model';
import { environment } from '../../environments/environment';
import { GymAppSettings } from '../models/gym-app-settings.model';

@Injectable({
  providedIn: 'root'
})
export class GymService {

  constructor(private http: HttpClient) {
  }

  getGym() {
    return this.http.get<Gym>(`${environment.baseUrl}/gym`)
  }

  getGymAppSettings() {
    return this.http.get<GymAppSettings>(`${environment.baseUrl}/gym/app-settings`)
  }
}
