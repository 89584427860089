<div class="w-full flex flex-col h-dvh">
    <div class="mx-[20%] mb-4">
        <fc-stepper [steps]="steps"></fc-stepper>
    </div>
    <div class="flex grow">
        <div>
            <div class="p-4 h-full">
                <div class="h-full rounded-lg overflow-hidden">
                    <img class="h-full" [src]="imageUrl"/>
                </div>
            </div>
        </div>
        <div class="grow flex flex-col gap-4 py-4 px-8 justify-between">
            <form [formGroup]="detailsForm" class="flex flex-col gap-4">
                <div class="font-semibold text-3xl text-gray-900 pb-4">Your details</div>
                <div class="font-semibold text-lg text-gray-900">Personal info</div>
                <div class="text-gray-600 text-sm border-b border-gray-200 pb-6">What's your name, how should we get in contact with you?</div>
                <div class="flex gap-4 items-end border-b border-gray-200 mt-4 pb-4">
                    <fc-text-input class="grow" formControlName="firstName" label="Name" [required]="true" placeholder="First name" [submitted]="submitted"></fc-text-input>
                    <fc-text-input class="grow" formControlName="lastName" placeholder="Last name" [submitted]="submitted"></fc-text-input>
                </div>
                <div class="flex gap-4 items-end border-b border-gray-200 mt-2 pb-4">
                    <fc-text-input class="grow" formControlName="mobile" label="Contact number" [required]="true" placeholder="Contact number" [submitted]="submitted"><phone-outline-icon #icon/></fc-text-input>
                </div>
                <div class="flex gap-4 items-end mt-2">
                    <fc-text-input class="grow" formControlName="email" label="Email address" [required]="true" placeholder="Email address" [submitted]="submitted"><envelope-outline-icon #icon /></fc-text-input>
                </div>
            </form>
            <app-footer (onBack)="onBack.emit()" (onNext)="onSubmit()" class="justify-self-end pb-4"></app-footer>
        </div>
    </div>
</div>
    