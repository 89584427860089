import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({ template: '' })
export class BaseComponent  {
  @Output() onNext = new EventEmitter();
  @Output() onBack = new EventEmitter();
  @Output() onLoad = new EventEmitter();
  @Output() onLoadCompleted = new EventEmitter();
  @Input() imageUrl: string | undefined;
}
