import { Component, Input, OnInit } from '@angular/core';
import { Step } from '@fc-lib';
import { BaseComponent } from '../base/base.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { StudentDetails } from '../../models/student-create.model';
import { StudentService } from '../../services/student.service';

@Component({
  selector: 'app-your-details',
  templateUrl: './your-details.component.html',
  styleUrls: ['./your-details.component.scss']
})
export class YourDetailsComponent extends BaseComponent implements OnInit {
  @Input() details: StudentDetails | undefined;

  steps: Step[] = [
    { title: 'Your details', currentStep: true, completed: false },
    { title: 'Emergency contacts', currentStep: false, completed: false },
    { title: 'Sign a waiver', currentStep: false, completed: false },
  ]
  submitted = false;

  detailsForm = this.fb.group({
    firstName: new FormControl<string>('', Validators.required),
    lastName: new FormControl<string>('', Validators.required),
    email: new FormControl<string>('', [Validators.email]),
    mobile: new FormControl<string>('', [Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)]),
  })

  constructor(private fb: FormBuilder, private studentService: StudentService) {
    super();
  }

  ngOnInit(): void {
    if (this.details) {
      this.detailsForm.patchValue(this.details);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.detailsForm.valid) {
      this.studentService.checkEmail(this.detailsForm.get('email')!.value!).subscribe(emailTaken => {
        if (emailTaken) {
          this.detailsForm.get('email')?.setErrors({notUnique: 'Email belongs to an existing student'})
        } else {
          this.onNext.emit(this.detailsForm.value);
        }
      });
    }
  }
}
